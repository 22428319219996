import gql from 'graphql-tag';
import Client from "../actions/client";


const AuthAction = {

    /**
     * Login response with token
     * @author: Arijit Deb Roy
     * @params: email: <string>, password: <string>, loginType: <string>
     */
    loginUser(email, password, loginType='backend') {
        const LOGIN_USER = gql`
                mutation loginUser($input: LoginInput!) {
                    loginUser(input: $input) {
                        code
                        status
                        message
                        data {
                          accessToken
                        }
                    }
                }
            `;
        return Client.mutate({
            mutation: LOGIN_USER,
            variables: {
                input: {
                    email: email,
                    password: password,
                    loginType: loginType
                }
            }
        });
    },

    /**
     * Registration response with id and verificationToken
     * @author: Arijit Deb Roy
     * @params: input: <JSON>
     */
    registerPartner(input) {
        const REGISTER_PARTNER = gql`
                mutation registerPartner($input: PartnerRegistrationInput!) {
                  registerPartner(input: $input) {
                    code
                    status
                    message
                    data {
                      userId,
                      verificationToken
                    }
                  }
                }
            `;
        return Client.mutate({
            mutation: REGISTER_PARTNER,
            variables: {
                input: input
            }
        });
    },

    /**
     * Forget Password token response with user email
     * @author: Susanta Chakraborty
     * @params: email: <string>, loginType: <string>
     */
    forgetPasswordUser(email) {
        const PASSWORD_FORGET_USER = gql`
            mutation forgetPasswordUser($input: UserPasswordForgetInput!){
                forgetPasswordUser(input: $input){
                code
                status
                message
                data{
                    totalCount
                    user{
                    userId
                    token
                    }
                }
                }
            }
        `;
        return Client.mutate({
          mutation: PASSWORD_FORGET_USER,
          variables: {
            input: {
              email: email,
            }
          }
        });
    },

    /**
     * Reset Password response with user token and new pass
     * @author: Susanta Chakraborty
     * @params: token: <string>, password: <string>
     */
    resetPasswordUser(token, password) {
        const PASSWORD_RESET_USER = gql`
                    mutation resetPasswordUser($input: UserPasswordResetInput!){
                      resetPasswordUser(input: $input){
                        code
                        status
                        message
                        data{
                          totalCount
                          user{
                            userId
                          }
                        }
                      }
                    }
                `;
        return Client.mutate({
          mutation: PASSWORD_RESET_USER,
          variables: {
            input: {
              token: token,
              password: password,
            }
          }
        });
    }
};

export default AuthAction;
